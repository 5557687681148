<template>
  <div class="layout-wrapper">
    <TheHeader />

    <div class="af-container">
      <slot />
    </div>

    <TheFooter />
  </div>
</template>

<script>
export default {
  components: {
    TheHeader: () => import("@/components/TheHeader"),
    TheFooter: () => import("@/components/TheFooter"),
  },
};
</script>

<style scoped lang="scss">
.layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-basis: 0;
  flex-direction: column;

  .af-container {
    flex-grow: 1;
    width: 100%;
    margin: 0 auto;
  }
}
</style>
